// @import '../../style/variables.scss';

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100vh;
  input {
    color: white !important;
  }
  &-Container {
    height: 74%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &-Name {
    font-family: Myriad Pro;
    font-size: 50px;
    letter-spacing: .2em;
  }

  &-Logo {
    width: 25vw;
  }

  &-Message {
    height:24px;
    font-family: Myriad Pro;
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    color: rgba(255, 0, 0, 0.5);
  }

  &-Form {
    width: 31.25vw;
    div::after {
      border-bottom: 2px solid white !important;
    }
    button {
      background-color: #00A5CF;
    }
    input {
      text-align: center;
      font-family: Myriad Pro;
      font-size: 20px;
    }
  }
  
  & &-Field {
    margin-bottom: 1em;
  }
  
  & &-Button {
    margin-top: 1em;
  }
}


